<template>
  <div class="">
    <header class="text-left">
      <div v-if="isCreate" class="elth-header">
        {{ translateCustomText("CHOOSE YOUR PRODUCTS") }}
      </div>
      <div v-else class="elth-header">
        {{ translateCustomText("MANAGE YOUR PRODUCTS") }}
      </div>
      <!-- <div class="elth-description">
        {{
          translateCustomText(
            "Select the products below to be shown in your shop"
          )
        }}
      </div> -->
    </header>
    <section class="filter my-3">
      <b-row>
        <b-col cols="12" md="6" class="pr-md-1">
          <input
            type="text"
            :name="`elth-input-control-${Date.now()}`"
            :id="`elth-input-control-${Date.now()}`"
            :placeholder="
              translateCustomText(`Search by product title or brand name`)
            "
            v-model.trim="filter_text"
            class="control-input px-3 py-2" />
        </b-col>

        <b-col cols="5" md="2" class="px-md-1 my-2 mt-md-0">
          <select
            @change="filterMethod()"
            v-model="selectedVendor"
            class="control-input px-3 py-2">
            <option class="elth-select-option" value="">
              <label>{{ translateCustomText("Brand") }}</label>
            </option>
            <option
              class="elth-select-option"
              :value="vendor"
              v-for="(vendor, index) in productVendors"
              :key="vendor + index">
              {{ vendor }}
            </option>
          </select>
          <i class="fa fa-chevron-down"></i>
        </b-col>
        <b-col cols="7" md="2" class="px-md-1 my-2 mt-md-0"
          ><select
            @change="filterMethod()"
            v-model="selectedType"
            class="control-input px-3 py-2">
            <option class="elth-select-option" value="">
              <label>{{ translateCustomText("Type of product") }}</label>
            </option>
            <!-- <b-form-select-option  class="elth-select-option" value=""><label>{{translateCustomText( 'All')}}</label></b-form-select-option> -->
            <option
              class=""
              :value="ProductType"
              v-for="(ProductType, index) in productType"
              :key="ProductType + index">
              {{ ProductType }}
            </option>
          </select>
          <i class="fa fa-chevron-down"></i>
        </b-col>
        <b-col cols="2" class="text-center d-flex align-items-center">
          <div class="d-flex align-items-center h-100 justify-content-end">
            <span
              class="product-specs-heading text-center clear-btn"
              @click="resetFilter"
              >{{ translateCustomText("Reset filters") }}</span
            >
          </div>
        </b-col>
      </b-row>
      <!-- <b-row class="d-none d-md-flex mt-2"> -->

      <!-- <b-col cols="3" class="d-flex align-items-center p-0"
          ><span
            style="
              font-weight: 400;
              font-size: 14px;
              color: var(--elthThemeColor);
              white-space: nowrap;
            ">
            {{ translateCustomText("Products in your shop") }}:
            <span style="color: #8d2909"
              >{{
                selectedProductsArray?selectedProductsArray.length : 0
              }}/{{ this.products ? totalRows : 0 }}</span
            ></span
          ></b-col
        > -->
      <!-- <b-col cols="12" class="pl-0"
          >
          <div class="d-flex justify-content-between align-items-center">
            <div class="invisible">this is invisible div </div>
            <div
              class="float-right justify-content-center d-flex align-items-center w-100 h-100">
              <span
                ><input
                  type="radio"
                  class="mr-2"
                  v-model="filter_selection"
                  name="productSelection"
                  value="all"
                  id="" />{{ translateCustomText("All") }}</span
              >
              <span class="pl-3"
                ><input
                  type="radio"
                  v-model="filter_selection"
                  class="mr-2"
                  name="productSelection"
                  value="selected"
                  id="" />{{ translateCustomText("Selected") }}</span
              >
              <span class="pl-3"
                ><input
                  type="radio"
                  v-model="filter_selection"
                  class="mr-2"
                  name="productSelection"
                  value="notSelected"
                  id="" />{{ translateCustomText("Not selected") }}</span
              >
            </div>
               <span
            class="product-specs-heading text-center clear-btn"
            @click="resetFilter"
            >{{ translateCustomText("Reset filters") }}</span
          >
          </div>
          </b-col
        >
      </b-row>
      <b-row class="d-md-none">
        <b-col cols="5">
          <select v-model="filter_selection" class="control-input px-3 py-2">
            <option class="elth-select-option" value="all"
              ><label>{{
                translateCustomText("All")
              }}</label></option
            >
            <option class="elth-select-option" value="selected"
              ><label>{{
                translateCustomText("Selected")
              }}</label></option
            >
            <option class="elth-select-option" value="notSelected"
              ><label>{{
                translateCustomText("Not selected")
              }}</label></option
            >
          </select>
          <i class="fa fa-chevron-down"></i>
        </b-col>
        <b-col cols="7">
          <div class="d-flex align-items-center h-100 justify-content-end">
            <span
             class="product-specs-heading text-center clear-btn"
             @click="resetFilter"
             >{{ translateCustomText("Reset filters") }}</span
           >
          </div>
        </b-col>
      </b-row> -->
    </section>
    <!-- Show Table for sm md lg devices -->
    <b-row v-if="isLoading">
      <b-col cols="12">
        <div class="table-loader">
          <b-spinner style="width: 3rem; height: 3rem"></b-spinner>
        </div>
      </b-col>
    </b-row>
    <div class="" v-if="isLoadFirstTime">
      <div
        v-for="index in 4"
        :key="index"
        class="integrationImgae bg-preload my-3"
        style="height: 55px; width: 100%; border-radius: 14px">
        <div class="into-preload"></div>
      </div>
    </div>
    <div class="" v-else>
      <b-table
        :sticky-header="true"
        thead-class="table-header"
        :items="productOnSelection"
        :fields="translatedFields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        head-variant="light"
        :busy="isLoadingTable"
        show-empty
        tbody-class="scrollable-tbody"
        @sort-changed="fetchData"
        :responsive="true"
        style="font-family: 'Montserrat'"
        class="d-none table m-0 table-container scrollable-tbody baldan-table d-sm-block"
        @row-clicked="clickableRow">
        <template #table-busy>
          <div class="text-center my-2" style="color: #8d2909">
            <b-spinner class="align-middle"></b-spinner>
            <!-- <strong>Loading...</strong> -->
          </div>
        </template>
        <template #head(checkbox)>
          <b-form-checkbox
            :id="`selectAll-${Date.now()}`"
            v-model="isSelectedAll"
            :value="true"
            :unchecked-value="false"
            :name="`selectAll-${Date.now()}`"
            @change="selectAllProductsEvent($event)"
            style="cursor: pointer"
            size="lg"
            class="ProductItemCheckbox">
          </b-form-checkbox>
        </template>
        <template #cell(checkbox)="data">
          <div class="tooltip text-center" style="position: relative">
            <div
              class="elth-tooltip text-center"
              style="
                border-radius: 5px;
                background: rgba(156, 70, 41, 0.8);

                box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
              "
              v-if="isChecked(data.item.external_id)">
              {{ translateCustomText("Remove from shop") }}
            </div>
            <div
              class="elth-tooltip"
              style="
                border-radius: 5px;
                background: rgba(80, 58, 60, 0.8);

                box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
              "
              v-else>
              {{ translateCustomText("Add to shop") }}
            </div>
          </div>
          <b-form-checkbox
            :id="`${data.item.id}-${Date.now()}`"
            v-model="selectedProductsArray"
            :name="`${data.item.id}-${Date.now()}`"
            :value="checkConvertIntegar(data.item.external_id)"
              @change="isNotRemoveCheck(checkConvertIntegar(data.item.external_id))"
            style="cursor: pointer"
            size="lg"
            :ref="'checkbox' + data.item.external_id"
            class="ProductItemCheckbox">
          </b-form-checkbox>
        </template>

        <template #cell(image)="data">
          <LazyImage
            :src="ImageSrc(data.item.image)"
            :key="`${data.index}-${data.item.id}`"
            style="
              width: 54px;
              height: 59px;
              border-radius: 12px;
              cursor: pointer;
            "
            alt="" />
        </template>
        <template #cell(title)="data">
          <a
            class="elth-title m-0"
            target="_blank"
            :href="data.item.detail_link"
            >{{ data.item.title }}
          </a>
        </template>
        <template #cell(price)="data">
          {{ getCurrency }} {{ data.item.price }}
        </template>
        <template #cell(vendor)="data">
          {{ data.item.vendor }}
        </template>
        <!-- v-if="quizId == baldanQuizID"  -->
        <template #cell(sku)="data" v-if="quizId == baldanQuizID">
          {{ data.item.sku }}
        </template>
        <!-- <template #cell(detail_link)="data">
          <b-button
            type="button"
            :href="data.item.detail_link"
            target="_blank"
            class="btn btn-elth">
            {{ translateCustomText("View") }}
          </b-button>
        </template> -->
        <template #empty="">
          <div class="" style="min-height: 170px">
            <h4 v-if="products.length < 1" class="mt-5">
              {{ translateCustomText("Products not found.") }}
            </h4>
            <h4 v-else class="mt-5">
              {{
                translateCustomText(
                  "Unfortunately, no product matches the selected filter criteria."
                )
              }}
            </h4>
          </div>
        </template>
      </b-table>
      <!-- Show for smaller devices -->
      <div
        class="d-block d-sm-none pagination-bar py-2"
        style="
          color: #fff;

          text-align: center;
          font-family: Montserrat;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
        ">
        <div class="d-flex px-4 justify-content-between">
          <b-form-checkbox
            :id="`selectAll-${Date.now()}`"
            v-model="isSelectedAll"
          
            :name="`selectAll-${Date.now()}`"
            @change="selectAllProductsEvent($event)"
            style="cursor: pointer"
            size="lg"
            class="ProductItemCheckbox">
          </b-form-checkbox>
          <div class="w-100">Pick products here</div>
          <b-form-checkbox
            :id="`selectAll-${Date.now()}`"
            v-model="isSelectedAll"
            :name="`selectAll-${Date.now()}`"
            @change="selectAllProductsEvent($event)"
            style="cursor: pointer"
            size="lg"
            class="ProductItemCheckbox invisible">
          </b-form-checkbox>
        </div>
      </div>
      <div
        class="products-list d-block d-sm-none scrollable-tbody"
        style="max-height: 300px; overflow-y: scroll">
        <div
          class="product-item bg-white"
          v-for="(product, index) in productOnSelection"
          :key="index + product.external_id">
          <div class="d-flex justify-content-center align-items-center">
            <div style="flex: 0.1">
              <b-form-checkbox
                :id="`${product.id}-${Date.now()}`"
                v-model="selectedProductsArray"
                :name="`${product.id}-${Date.now()}`"
                :value="checkConvertIntegar(product.external_id)"
                style="cursor: pointer"
                size="lg"
                :ref="'checkbox' + index"
                class="ProductItemCheckbox">
              </b-form-checkbox>
            </div>
            <div style="flex: 0.2; margin-right:6px;">
              <LazyImage
                :src="ImageSrc(product.image)"
                :key="`${index}-${product.id}`"
                style="
                  width: 54px;
                  height: 59px;
                  border-radius: 12px;
                  cursor: pointer;
                "
                alt="" />
            </div>
            <div style="flex: 0.7">
              <div class="product-md-heading text-left">
                <a
                  class="elth-title m-0"
                  target="_blank"
                  :href="product.detail_link"
                  >{{ product.title }}
                </a>
              </div>
              <div class="d-flex justify-content-between text-left flex-wrap">
                <div class="">
                  <p class="mb-1" style="font-weight: 700; font-size: 12px">
                    {{ translateCustomText("Brand") }}
                  </p>
                  <div class="product-md-heading text-left" style="width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;" >
                    {{ product.vendor }}
                  </div>
                </div>
                <div class="">
                  <p class="mb-1 text-left" style="font-weight: 700; font-size: 12px">
                    {{ translateCustomText("Price") }}
                  </p>
                  <div class="product-md-heading text-left">
                    {{ getCurrency }} {{ product.price }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <b-row class="justify-content-center" v-if="quizId == baldanQuizID">
            <b-col cols="4"> </b-col>
            <b-col cols="4"> </b-col>
            <b-col cols="4">
              <p class="mb-1" style="font-weight: 700; font-size: 12px">
                {{ translateCustomText("Code") }}
              </p>
              <div class="product-md-heading">
                {{ product.sku }}
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
    <div class="pagination-bar p-2">
      <div class="d-flex justify-content-between">
        <div class="d-flex justify-content-md-between w-100">
          <div
            class="col-md-5 col-10 p-0  text-left"
            :class="[
              selectedProductsArray.length || selectFilter > 0 ? 'visible' : 'invisible',
            ]">
            <span style="color: #fff" v-if="!selectFilter"
              >{{ selectedProductsArray ? selectedProductsArray.length : 0 }}
              {{ translateCustomText("Selected") }}</span
            >
              <span v-else-if="selectFilter && !checkFilterIsEmpty" style="color: #fff" class="pl-2">
              {{ translateCustomText("All") }} {{  getShopProductsCount}}
              {{ translateCustomText("products are selected") }}
              <span @click="toggleSelectAllStatus()" class="select-all-span">{{
                translateCustomText("Undo")
              }}</span>
            </span>
          <span v-if="totalRows>25">
            <span
              v-if="!selectFilter&& checkFilterIsEmpty"
              class="select-all-span pl-2"
              @click="toggleSelectAllStatus()"
              >{{ selectFilter ? "translateCustomText('Undo')" : translateCustomText("Select all") }}
              {{ totalRows }} {{ translateCustomText("products") }}</span
            >
            <span v-else-if="selectFilter && checkFilterIsEmpty" style="color: #fff" class="pl-2">
              {{ translateCustomText("All") }} {{  getShopProductsCount }}
              {{ translateCustomText("products are selected") }}
              <span @click="toggleSelectAllStatus()" class="select-all-span">{{
                translateCustomText("Undo")
              }}</span>
            </span>
            </span>
            <span v-else class="invisible" style="color: #fff" >
              {{ translateCustomText("All") }} {{  getShopProductsCount}}
              {{ translateCustomText("products are selected") }}
             
            </span>
          </div>
          <div
            class="d-flex p-0 col-2  justify-content-end justify-content-md-center"
            style="gap: 5px; flex: 1">
            <button
              :disabled="currentPage <= 1 || filter_selection != 'all'"
              @click="
                () => {
                  currentPage += -1;
                  movePagination();
                }
              "
              class="pagination-btn">
              <b-icon icon="caret-left"></b-icon>
            </button>
            <button
              :disabled="
                !(currentPage < Math.ceil(totalRows / perPage)) ||
                filter_selection != 'all'
              "
              @click="
                () => {
                  currentPage += 1;
                  movePagination();
                }
              "
              class="pagination-btn">
              <b-icon icon="caret-right"></b-icon>
            </button>
          </div>

                <div

           class="d-none d-md-block col-md-5 p-0 col-0 invisible">        
            <span style="color: #fff" class="pl-2">
             
              <span  class="select-all-span">{{
                translateCustomText("Undo")
              }}</span>
            </span>
           
           
          </div>
          
        </div>
      </div>
    </div>
    <!-- <div
     
      class="text-center my-3"
      style="
        color: #503a3c;
        font-family: Montserrat;
        font-size: 18px;
        font-style: normal;
      ">
      {{ translateCustomText("Products in your shop") }}:
      <span style="font-weight: 600"
        >{{ getProductCount ? getProductCount : 0 }}/{{
          totalStoredProducts
        }}
      </span>
    </div> -->
  </div>
</template>

<script>
import axios from "axios";
import { debounce } from "lodash";
import TranslationMixin from "../../../customize/mixin/TranslationMixin";
import { mapGetters } from "vuex";

export default {
  mixins: [TranslationMixin],
  components: {},
  props: {
    isCreate: Boolean,
    options: Array,
    email: String,
    apiToken: String,
    selectedProducts: Array,
    loading: Boolean,
    quizId: Number,
    // isCreate:Boolean
  },
  watch: {
    notRemoveSelectAllArray:{
handler() {
    if(this.notRemoveSelectAllArray.length>0)
  {
    this.isNotRemoveSelectAllArray=true
  }
  else
  {
     this.isNotRemoveSelectAllArray=true
  }
   this.$emit('isNotRemoveProducts',{isNotRemoveSelectAllArray:this.isNotRemoveSelectAllArray,notRemoveSelectAllArray: this.notRemoveSelectAllArray})
        this.$emit("update", this.selectedProductsArray);
        this.$emit("payload", this.payload);
},
deep:true
    },
        productOnSelection:{
      handler(newValue){
this.isSelectedAll = newValue.every((product) =>
            this.selectedProductsArray.includes(this.checkConvertIntegar(product.external_id))
          );
      },
      deep:true
    },
    selectedProductsArray: {
      handler(newValue) {
        this.isSelectedAll = this.productOnSelection.every((product) =>
          newValue.includes(this.checkConvertIntegar(product.external_id))
        );
         if(!this.selectFilter)
          {
            this.isNotRemoveSelectAllArray=false;
            this.notRemoveSelectAllArray=[];
          }
        this.$emit('isNotRemoveProducts',{isNotRemoveSelectAllArray:this.isNotRemoveSelectAllArray,notRemoveSelectAllArray: this.notRemoveSelectAllArray})
        this.$emit("update", newValue);
        this.$emit("payload", this.payload);
      },
      deep: true,
    },
    filter_text() {
      this.currentPage = 1;
      this.handleProductSearchDebounced();
    },
    // selectedProducts: {
    //   handler(newValue) {
    //     this.selectedProductsData = newValue;
    //     this.isSelectedAll =
    //       this.productOnSelection.every((e) =>
    //         this.getSelectedProduct.includes(
    //           this.checkConvertIntegar(e.external_id)
    //         )
    //       ) && this.productOnSelection.length > 0;
    //   },
    //   deep: true,
    // },
    
  },
  computed: {
    checkFilterIsEmpty(){
     return this.selectedVendor==''&&this.selectedType==''&&this.filter_text==''
    },
    getProductCount(){
    return  this.selectFilter?this.totalRows:this.selectedProductsArray.length
    },
      getShopProductsCount(){
    let count = 0;
    
      if(this.selectFilter)
      count = this.totalProducts-this.notRemoveSelectAllArray.length
    else
      count= this.selectedProductsArray.length
    
   
   return count
  },
    payload() {
      return {
        save_all_status: this.selectFilter,
        filter: {
          title: this.filter_text,
          produc_type: this.selectedType,
          vendor: this.selectedVendor,
          order_by: this.sortBy,
          order_value: this.sortDesc ? "desc" : "asc",
          option_ids: this.options,
        },
      };
    },
    translatedFields() {
      return this.fields.map((field) => {
        if (this.quizId != this.baldanQuizID && field.key == "sku") return;
        if (field.label !== "") {
          return {
            ...field,
            label: this.translateCustomText(field.label),
          };
        }
        return field;
      });
    },
    ...mapGetters(["getPreviewSettings", "getEnvConst"]),
    getQuizID() {
      return this.getPreviewSettings.quizId;
    },
    getEnv() {
      // getEnvConst
      return this.getEnvConst;
    },
    getCurrency() {
      return this.getPreviewSettings.currencySign.sign;
    },
    isLoading() {
      return this.loading ? this.loading : false;
    },
    getSelectedProduct() {
      return this.selectedProductsData;
    },
    productOnSelection() {
      return  this.products;
    },
    baldanQuizID() {
      return this.getEnv?.VUE_APP_ITALIAN_QUIZID ?? null;
    },
    getProductsTable() {
      this.filter_text.trim().toLowerCase();

      if (this.filter_selection == "selected")
        return this.selectedProductsArray;
      else if (this.filter_selection == "all") return this.products;
      else
        return this.products.filter(({ external_id }) => {
          return !this.getSelectedProduct.includes(
            this.checkConvertIntegar(external_id)
          );
        });
    },
   
  },
  methods: {
     isNotRemoveCheck(externalId){
   if(this.selectFilter)
          {
            this.isNotRemoveSelectAllArray=true;
     
      const index = this.notRemoveSelectAllArray.findIndex((value)=>value==externalId)
     
            if(index>-1)
            {
              this.notRemoveSelectAllArray.splice(index,1)
            }
            else{
            this.notRemoveSelectAllArray.push(externalId)
            }
            
          }
          
          
          },
    toggleSelectAllStatus() {
      this.selectFilter = !this.selectFilter;
      this.selectAllProductsEvent(this.selectFilter, true);
      this.$emit("payload", this.payload);
    },
    updateSelected(products) {
      this.totalSavedProduct = products;
    },
    filterMethod() {
      this.currentPage = 1;
      this.getProductsCreateUpdate();
    },
    handleProductSearchDebounced: debounce(function () {
      this.getProductsCreateUpdate();
    }, 500),
    fetchData({ sortDesc, sortBy }) {
      this.currentPage = 1;
      this.sortDesc = sortDesc;
      this.sortBy = sortBy;

      this.getProductsCreateUpdate();
    },
    movePagination() {
      this.getProductsCreateUpdate();
    },
    async getProductsCreateUpdate() {
      await this.getCreateProducts();

      this.isLoadFirstTime = false;
    },
    clickableRow(e) {
      const index = this.selectedProductsArray.findIndex(
        (selected) =>
          this.checkConvertIntegar(e.external_id) ==
          this.checkConvertIntegar(selected)
      );

      if (index > -1) {
        this.selectedProductsArray.splice(index, 1); // Remove the row
      } else {
        this.selectedProductsArray.push(
          this.checkConvertIntegar(e.external_id)
        ); // Add the row
      }
    },
    translateCustomText(key) {
      return this.translateToItalian(key, this.getQuizID);
    },
    openBaldenProducts(link) {
      if (link) {
        window.open(link, "_blank");
      }
    },
    isChecked(external_id) {
      return this.selectedProductsData.includes(
        this.checkConvertIntegar(external_id)
      );
    },
    async selectAllProductsEvent(e) {
      if (e) {
        try {
          if(this.selectFilter)
        {
      this.productOnSelection.forEach(product=>{
    const index = this.notRemoveSelectAllArray.findIndex((value)=>value==product.external_id)
     
            if(index>-1)
            {
              this.notRemoveSelectAllArray.splice(index,1)
            }
           
    })
      }
          // this.selectedProductsArray=[];
          this.selectedProductsArray = [
            ...new Set([
              ...this.selectedProductsArray,
              ...this.productOnSelection.map((product) =>
                this.checkConvertIntegar(product.external_id)
              ),
            ]),
          ];
          // const newSelected = Array.from({
          //   length: this.productOnSelection.length,
          // });
          // for (let i = 0; i < this.productOnSelection.length; i++) {
          //   const convertedId = this.checkConvertIntegar(
          //     this.productOnSelection[i].external_id
          //   );
          //   newSelected[i] = convertedId;
          // }
          // this.selectedProductsData = [
          //   ...new Set([...this.selectedProductsData, ...newSelected]),
          // ];
        } catch (error) {
          console.log(error);
          if (error) {
            this.$toasted.show(
              this.translateCustomText(`An error has occurred`),
              {
                theme: "toasted-primary",
                position: "bottom-center",
                duration: 2000,
              }
            );
          }
        }
      } else {
         if(this.selectFilter)
        {
        let newNotRemoveSelectAllArray =[]
               this.productOnSelection.forEach(product=>{
              const index = this.notRemoveSelectAllArray.findIndex((value)=>value==this.checkConvertIntegar(product.external_id))
            if(index<=-1)
            {
              newNotRemoveSelectAllArray.push(this.checkConvertIntegar(product.external_id))
            }
           
    })

         this.notRemoveSelectAllArray = [...new Set([...newNotRemoveSelectAllArray, ...this.notRemoveSelectAllArray])];
         }
         this.selectedProductsArray = []
       
      }
    },
    spliceByIDs() {
      this.products.forEach((obj) => {
        const index = this.selectedProductsArray.findIndex(
          (item) => item == obj.external_id
        );
        if (index != -1) {
          this.selectedProductsArray.splice(index, 1);
        }
      });
    },
    resetFilter() {
      // this.selectAllProducts = false;
      this.selectedVendor = "";
      this.selectedType = "";
      this.filter_text = "";
      this.filter_selection = "all";
      this.getCreateProducts();
    },
    toggleSelectedProduct(event, id) {
      if (["TD"].includes(event.target.tagName))
        this.$refs["checkbox" + id][0].$refs.input.click();
    },
    toggleSingleProduct() {
      this.isSelectedAll =
        this.productOnSelection.every((e) =>
          this.getSelectedProduct.includes(
            this.checkConvertIntegar(e.external_id)
          )
        ) && this.productOnSelection.length > 0;
    },
    checkConvertIntegar(value) {
      const parsedValue = parseInt(value, 10);
      if (Number.isNaN(parsedValue)) {
        return value;
      }
      return parsedValue;
    },
    ImageSrc(img) {
      if (img) return img;
      return 'https://images.quizell.com/website/default_start_page.png';
    },
    async getCreateProducts() {
      const data = {
        api_token: this.apiToken,
        email: this.email,
        title: this.filter_text,
        produc_type: this.selectedType,
        vendor: this.selectedVendor,
        order_by: this.sortBy,
        order_value: this.sortDesc ? "desc" : "asc",
        option_ids: this.options,
        page: this.currentPage,
      };
      this.isLoadingTable = true;
      try {
        const response = await axios.post(
          "/api/custom/collection/filter/products",
          data
        );
         if(this.filter_selection=='shop')
        {
        this.isSelectedAll =true;
        
          this.selectedProductsArray =[... new Set([...this.selectedProductsArray, ...this.products.map(product => this.checkConvertIntegar(product.external_id))])];
          this.selectedProductsArray =  this.selectedProductsArray.filter(product=>!this.notRemoveSelectAllArray.includes(product));
         
        }
        this.products = response.data.data.data;
        if(this.selectFilter)
        {
          this.selectedProductsArray = [
            ...new Set([
              ...this.selectedProductsArray,
              ...this.products.map((product) =>
                this.checkConvertIntegar(product.external_id)
              ),
            ]),
          ];
          this.selectedProductsArray= this.selectedProductsArray.filter(product=>!this.notRemoveSelectAllArray.includes(product))
        }
        this.totalRows = response.data.data.total;
        if(this.totalProducts==0)
        {
           this.totalProducts=this.totalRows;
        }
        this.currentPage = response.data.data.current_page;

        // if (!this.isCreate) {
        //   // Convert selectedProducts once if necessary
        //   // const convertedSelectedProducts = this.selectedProducts.map(id => this.checkConvertIntegar(id));

        //   const newProducts = this.products.filter(({ external_id }) =>
        //     this.totalSavedProduct.includes(this.checkConvertIntegar(external_id))
        //   );

        //   console.log(this.totalSavedProduct)
        //   this.selectedProductsArray=newProducts;
        //   // Add only new products not already in selectedProductsArray
        //   // newProducts.forEach(product => {
        //   //   if (!this.selectedProductsArray.find(({ external_id }) => external_id == product.external_id)) {
        //   //     this.selectedProductsArray.push(product);
        //   //      console.log(product)
        //   //   }

        //   // });
        // }
      } catch (error) {
        console.error(error);
      }
      this.isLoadingTable = false;
    },

    async getProductsTypeBrand() {
      const data = {
        api_token: this.apiToken,
        option_ids: this.options,
      };

      this.isLoadingTable = true;
      await axios
        .post("/api/custom/collection/current/brands", data)
        .then((resp) => {
          this.productVendors = resp.data.data.brands;
          this.productType = resp.data.data.types;
        })
        .catch((error) => {
          console.log(error);
        });
      this.isLoadingTable = false;
    },
    async getTypesAndVendors() {
      this.productVendors = [
        ...new Set(this.products.map((item) => item.vendor)),
      ];
      this.productType = [
        ...new Set(this.products.map((item) => item.product_type)),
      ];
    },
  },

  async mounted() {
    await this.getProductsTypeBrand();
    (this.totalSavedProduct = [...this.selectedProducts]),
      await this.getProductsCreateUpdate();
    this.totalStoredProducts = this.totalRows;
    this.selectFilter =
      this.totalStoredProducts == this.selectedProductsArray.length;

    this.$emit("loaded");
  },
  data() {
    return {
     totalProducts :0,
      notRemoveSelectAllArray:[],
      isNotRemoveSelectAllArray:false,
      totalStoredProducts: 0,
      totalSavedProduct: [],
      selectFilter: false,
      selectedProductsArray: this.selectedProducts,
      isLoadFirstTime: true,
      isLoadingTable: false,
      currentPage: 1,
      totalRows: 1,
      perPage: 25,
      products: [],
      sortBy: "title",
      sortDesc: false,
      fields: [
        {
          key: "checkbox",
          label: "",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "image",
          label: "Image",
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap",
        },
        {
          key: "title",
          label: "Title",
          sortable: true,
          thClass: "max-width-300 text-nowrap",
          tdClass: "max-width-",
        },
        {
          key: "price",
          label: "Price",
          sortable: true,
          thClass: "max-width-150 text-nowrap",
          tdClass: "max-width-150",
        },
        {
          key: "vendor",
          label: "Brand",
          sortable: true,
          thClass: "text-nowrap",
        },
        {
          key: "sku",
          label: "Code",
          tdClass: "text-center",
          sortable: true,
          thClass: "text-nowrap",
        },
        // {
        //   key: "detail_link",
        //   label: "Product page",
        //   thClass: "max-width-150 text-nowrap",
        //   tdClass: "max-width-150",
        // },
      ],

      scripts: [],
      selectedProductsData: this.selectedProducts,
      filter_selection: "all",
      filter_text: "",
      productVendors: [],
      productType: [],
      selectedType: "",
      isSelectedAll: false,
      selectedVendor: "",
      headerStyle: {
        backgroundColor: "#503A3C",
        color: "#FFFFFF",
        height: "40px",
      },
      productStyle: {
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 10,
        paddingBottom: 10,
        pagePaddingLeft: "12",
        pagePaddingRight: "12",
        pagePaddingTop: "12",
        pagePaddingBottom: "12",
        imageRatio: "potrait",
        imageFit: "Fit",
        titleTextOpacity: 100,
        titleColor: "#503A3C",
        priceColor: "#503A3C",
        backgroundColor: "#FFFFFF",
        textDecoration: "unset",
        fontWeight: "normal",
        fontStyle: "normal",
        textAlign: "center",
        fontSize: "15",
        fontFamily: "Montserrat",
        borderRadius: "6",
        borderSize: 0,
        borderColor: "#000000",
        buttonBgColor: "#f6ba29",
        buttonTextColor: "#FFFFFF",
        buttonBorderRadius: "4",
        buttonBorderSize: "0",
        buttonBorderColor: "#ffffff",
        bulletDescriptionfontFamily: "Poppins",
        bulletDescriptionfontSize: 15,
        bulletDescriptionfontWeight: "normal",
        bulletDescriptionfontStyle: "normal",
        bulletDescriptiontextDecoration: "unset",
        bulletDescriptiontitleColor: "#000",
        bulletDescriptiontitleTextOpacity: 100,
        bulletDescriptiontextAlign: "left",
      },
      headingStyle: {
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 10,
        paddingBottom: 10,
        marginTop: "0",
        marginBottom: "6",
        marginLeft: "48",
        marginRight: "48",
        width: "800",
        height: 100,
        textOpacity: 100,
        color: "#2A1C1E",
        rotate: 0,
        lineHeight: "1.4",
        textDecoration: "unset",
        fontWeight: "bold",
        fontStyle: "normal",
        textAlign: "center",
        fontSize: "25",
        fontFamily: "Open Sans",
      },

      sorting: {
        column: null,
        isNumeric: false,
        order: "asc",
      },
      // Store previous clicked header to reset its state
      prevClickedHeader: null,
      imageHeader: "",
    };
  },
};
</script>
<style>
.elth-title {
  cursor: pointer;
  text-decoration: none;
  color: inherit;
}
.elth-title:hover {
  color: inherit;
}
tbody tr {
  background-color: #fff;
  color: var(--elthThemeColor);
}
.table-header {
  background: #503a3c;
  color: #fff;
}
.table-container {
  height: 300px;
  overflow-y: auto;
}
.max-width-400 {
  width: 400px;
}
.max-width-150 {
  width: 200px;
}
@supports (position: sticky) {
  .table.b-table > thead > tr > th {
    background: #503a3c;
    color: white;
    font-weight: 600;
  }
}
</style>
<style scoped>
* {
  --elthThemeColor: #503a3c;
}
.select-all-span {
  color: rgba(0, 255, 240, 1);
  cursor: pointer;
}
.select-all-span:hover {
  text-decoration: underline;
}
.pagination-bar {
  background: #503a3c;
  border-radius: 1px;
}
.pagination-bar:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.pagination-btn {
  border-radius: 5px;
  /* */
  background: #fff;
  border: 1px solid rgba(80, 58, 60, 1);
  color: rgba(80, 58, 60, 1);
}
.pagination-btn:hover {
  background: #e1e0e0;
}
active {
  box-shadow: 2px 2px 5px #e1e0e0;
}
.pagination-btn:disabled {
  background: rgb(198, 196, 196);
  cursor: not-allowed;
}
.stickyHeader {
  position: sticky;
  position: -webkit-sticky;
  top: 0px;
  z-index: 2;
}
.table-container .b-table tbody tr {
  background-color: #fff;
  color: var(--elthThemeColor);
}
select {
  padding-right: 30px;
}
.btn-elth {
  border: 1px solid #503a3c;
  border-radius: 5px;
  transition: 0.3s ease all;
  color: #503a3c;
  background: transparent !important;
}
.btn-elth:hover {
  background: #503a3c !important;
  color: #ffffff;
}
.ProductItemCheckbox >>> .custom-control-input:checked ~ label::before {
  background: #a47c6e !important;
}

.ProductItemCheckbox
  >>> .custom-control-input:focus
  ~ .custom-control-label::before {
    box-shadow: none;
  border-color: #bdc3ca!important;
}
.table td {
  text-align: center;
}
.table-container >>> table tbody > tr > td {
  vertical-align: middle;
}
.scrollable-tbody::-webkit-scrollbar {
  width: 22px;
  background: #503a3c !important;
  /* background:white; */
}

.scrollable-tbody::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0);
  border: 1px solid var(--elthThemeColor);
  background-clip: padding-box;
  background: #fff !important;
  margin-top: 55px;
}

.scrollable-tbody::-webkit-scrollbar-thumb {
  background-color: var(--elthThemeColor);
  outline: 1px solid var(--elthThemeColor);
  background-image: linear-gradient(white, white), linear-gradient(white, white),
    linear-gradient(white, white);
  background-position: 50% 38%, 50% 50%, 50% 65%;
  background-repeat: no-repeat;
  background-size: 50% 1px, 50% 1px, 50% 1px;
}

/* .scrollable-tbody::-webkit-scrollbar-button {
  border: 1px solid var(--elthThemeColor);
  background-size: 10px 10px;
  background-repeat: no-repeat;
  background-position: center center;
  height: 22px;
  width: 1em;
  -webkit-box-shadow: inset 1px 1px 2px rgba(0, 0, 0, 0.2);
} */

/* .scrollable-tbody::-webkit-scrollbar-button:horizontal:increment {
  background-image: url(https://dl.dropboxusercontent.com/u/55165267/icon2.png);
}

.scrollable-tbody::-webkit-scrollbar-button:end:increment {
  background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjE2cHgiIGhlaWdodD0iMTZweCIgdmlld0JveD0iMCAwIDQwNC4zMDggNDA0LjMwOSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNDA0LjMwOCA0MDQuMzA5OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxnPgoJPHBhdGggZD0iTTAsMTAxLjA4aDQwNC4zMDhMMjAyLjE1MSwzMDMuMjI5TDAsMTAxLjA4eiIgZmlsbD0iIzAwMDAwMCIvPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+Cjwvc3ZnPgo=);
}

.scrollable-tbody::-webkit-scrollbar-button:start:decrement {
  background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjE2cHgiIGhlaWdodD0iMTZweCIgdmlld0JveD0iMCAwIDI1NSAyNTUiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDI1NSAyNTU7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPGc+Cgk8ZyBpZD0iYXJyb3ctZHJvcC11cCI+CgkJPHBvbHlnb24gcG9pbnRzPSIwLDE5MS4yNSAxMjcuNSw2My43NSAyNTUsMTkxLjI1ICAgIiBmaWxsPSIjMDAwMDAwIi8+Cgk8L2c+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPC9zdmc+Cg==);
} */
thead,
tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}
th {
  cursor: pointer;
  font-weight: 600;
}
.elth-tooltip {
  position: absolute;
  min-width: 184px;
  height: 27px;
  left: 20px;
  top: -30px;
  border-radius: 5px;
  color: #ffff;
  font-size: 14px;
  line-height: 2;
}
td .tooltip {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}
td:hover .tooltip {
  opacity: 1;
  visibility: visible;
}
.product-item {
  padding: 8px 20px;
  /* min-height: 120px; */
  border-bottom-color: #cac7c7;
  border-bottom-style: solid;
  border-bottom-width: 1px;

  border-left-color: var(--elthThemeColor);
  border-left-style: solid;
  border-left-width: 1px;

  border-right-color: var(--elthThemeColor);
  border-right-style: solid;
  border-right-width: 1px;
  color: var(--elthThemeColor);
}
.product-specs-heading {
  font-weight: 700;
  font-size: 16px;
  overflow-wrap: anywhere;
}
.product-md-heading {
  color: #503a3c;

  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.elth-input-control {
  border: 1px solid var(--elthThemeColor);
  font-size: 14px;
}
.elth-input-control:focus {
  border-color: var(--elthThemeColor);
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(80, 58, 60, 0.25);
}
.clear-btn {
  cursor: pointer;
  white-space: nowrap;
  font-family: Montserrat;
  font-size: 18px !important;
  font-style: normal;
  font-weight: 400 !important;
  transition: 0.5s ease all;
  color: #8d2909;
}
.clear-btn:hover {
  text-decoration: underline;
}
.select-all {
  width: 100%;
  min-height: 41px;
  background: rgba(80, 58, 60, 0.08);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
span input[type="radio"] {
  height: 16px;
  width: 16px;
  vertical-align: middle;
  accent-color: var(--elthThemeColor);
}
.elth-header {
  color: #503a3c;
  font-family: Montserrat;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 100% */
  text-transform: uppercase;
}
.asc:after {
  content: " Ã¢â€“Â²";
}
.desc:after {
  content: " Ã¢â€“Â¼";
}
.elth-description {
  font-weight: 400;
  font-size: 14px;
}
.table-loader {
  position: absolute;
  width: 100%;
  height: 350px;
  /* top: 150px; */
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.5);
  z-index: 40;
}
.baldan-table >>> .b-table thead tr > [aria-sort="none"],
.baldan-table .b-table tfoot tr > [aria-sort="none"],
.table-header tr > [aria-sort="none"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' viewBox='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='white'  d='M51 1l25 23 24 22H1l25-22zM51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e") !important;
}

.baldan-table >>> .b-table thead tr > [aria-sort="ascending"],
.baldan-table .b-table tfoot tr > [aria-sort="ascending"],
.table-header tr > [aria-sort="ascending"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='SaddleBrown' d='M51 1l25 23 24 22H1l25-22z'/%3e%3cpath fill='SaddleBrown' opacity='.3' d='M51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e") !important;
}

.baldan-table >>> .b-table thead tr > [aria-sort="descending"],
.baldan-table .b-table tfoot tr > [aria-sort="descending"],
.table-header tr > [aria-sort="descending"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='SaddleBrown' opacity='.3' d='M51 1l25 23 24 22H1l25-22z'/%3e%3cpath fill='SaddleBrown' d='M51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e") !important;
}
.customPagination >>> li > button {
  color: #503a3c;
}

.customPagination >>> li.active > button {
  color: white;
  background-color: #503a3c !important;
  border-color: #503a3c;
}
.customPagination >>> li > button:hover {
  color: white;
  background-color: #9c4629 !important;
  border-color: #9c4629;
}
@media (max-width: 767px) {
  .select-all {
    width: 319px !important;
  }
  .scrollable-tbody::-webkit-scrollbar-track {
    margin-top: 0px;
  }
  .elth-header {
    font-size: 22px;
    text-align: center;
  }
}
.control-input {
  border-radius: 15px;
  border: 1px solid #503a3c;
  /* max-width: 604px; */
  width: 100%;
  background: #fff;
}
select {
  color: #503a3c;
}
.control-input {
  border-radius: 15px;
  border: 1px solid #503a3c;
  width: 100%;
  background: #fff;
}

/* Mozilla Firefox */
@-moz-document url-prefix() {
  .control-input {
    border-radius: 15px;
    border: 1px solid #503a3c;
    width: 100%;
  }
}

/* iOS devices */
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .control-input {
    border-radius: 15px;
    border: 1px solid #503a3c;
    width: 100%;
  }
}

.control-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  /* no standardized syntax available, no ie-friendly solution available */
}
.control-input + i.fa {
  float: right;
  margin-top: -27px;
  font-size: 14px;
  margin-right: 5px;
  display: block;
  /* z-index: 111111; */
  position: relative;
  /* this is so when you click on the chevron, your click actually goes on the dropdown menu */
  pointer-events: none;
  /* everything after this is just to cover up the original arrow */
  /* (for browsers that don't support the syntax used above) */
  background-color: #fff;
  padding-right: 5px;
}
.elt-btn {
  /* background: #503A3C; */
  color: #fff;
  border-radius: 15px;
  min-width: 119px;
  min-width: 168px;
  color: #9c4629;
  border: 1px solid #9c4629;
  text-align: center;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
}
</style>